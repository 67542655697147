import styled from 'styled-components'
import tw from 'twin.macro'

interface ContainerProps {
  loading?: 'loading'
}

export const Container = styled.div<ContainerProps>`
  ${tw`
    bg-white
    items-center
    justify-center
    max-h-screen
    mt-6
    mx-0
    overflow-auto
    pb-4
    px-4
    shadow-lg
    w-full

    sm:h-app
    sm:mt-0
    sm:overflow-y-scroll
    sm:pb-20
    sm:pt-1
    sm:px-8
    sm:shadow-none
    sm:w-2/6
  `}
  display: ${(props) => (props.loading === 'loading' ? 'flex' : 'block')};
`
