import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'

import { DropdownArrow, selectStyles, theme } from '../ReactSelect.styles'

import type {
  IndicatorProps,
  OptionsType,
  OptionTypeBase,
  Props,
} from 'react-select'

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => (
  <components.DropdownIndicator {...props}>
    <DropdownArrow />
  </components.DropdownIndicator>
)

interface Select {
  defaultValue?: OptionTypeBase
  onChange?: (arg0: any) => void
  options: OptionsType<OptionTypeBase>
  value?: OptionTypeBase
}

const Select: React.FC<Select> = ({
  defaultValue,
  onChange,
  options,
  value,
}: Props<OptionTypeBase>) => (
  <ReactSelect
    components={{ DropdownIndicator }}
    defaultValue={defaultValue}
    isSearchable={false}
    onChange={onChange}
    options={options}
    styles={selectStyles}
    theme={theme}
    value={value}
  />
)

Select.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
}

export default Select
