import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import debounce from 'lodash.debounce'
import { useInput } from 'react-hookedup'

import { note as noteConfig } from '$/config'
import { useRouteState, useDispatch, useMaximumNumberOfVehicles } from '$/hooks'
import type { ApiGeocodeResult, Optional, Stop } from '$/types'

import * as StopAPI from '../../api/stop'
import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete'
import {
  AutocompleteContainer,
  RouteContainer,
} from '../PlacesAutocomplete/PlacesAutocomplete.styled'
import {
  CommentBox,
  CommentButton,
  Counter,
  DeleteButton,
  InputAndButtonContainer,
} from './RouteStop.styled'

interface RouteStopInterface {
  id: string
  index: number
  stop: Optional<Stop>
  color: string
  freeStopExists?: boolean
  setEmptyStopVisible?: (visible: boolean) => void
}

const createStopCounter = (index: number) => `${index + 1}`.padStart(2, '0')

const RouteStop = ({
  id,
  index,
  stop,
  color,
  freeStopExists,
  setEmptyStopVisible,
}: RouteStopInterface): React.ReactElement => {
  const route = useRouteState()
  const dispatch = useDispatch()
  const textarea = useInput(stop?.notes ?? '')
  const [isCommentOpen, setIsCommentOpen] = useState(false)
  const numberOfDrivers = useMaximumNumberOfVehicles()

  const routeRef = route!.ref!

  const toggleCommentBox = () => {
    setIsCommentOpen(!isCommentOpen)
  }

  const saveComment = useCallback(
    debounce((value: string, currentStop) => {
      const data = {
        notes: value,
      }

      StopAPI.update(routeRef!, currentStop!.id, data)
    }, noteConfig.millisecondsBeforeSave),
    [],
  )

  const handleDelete = async () => {
    // If stop input is filled, delete from Firebase
    // If stop input is blank, delete from stop state
    // if there are more than one blank
    if (stop) {
      await StopAPI.remove(route, stop, id)
    } else {
      // Prevent removing the only empty route
      if (freeStopExists) return

      dispatch({ type: 'DELETE_STOP', id, numberOfDrivers })
    }
  }

  const handleSelected = async (geocodeResult: ApiGeocodeResult) => {
    const doc = {
      ...geocodeResult,
      type: 'stop',
    }

    await StopAPI.save(route!.ref, doc as Stop, id)
    const { latitude, longitude } = doc

    dispatch({ type: 'UPDATE_MAP_CENTER', latitude, longitude })
  }

  const handleCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    currentStop: Stop,
  ) => {
    const { maxChars } = noteConfig

    if (e.target.value.length <= maxChars) {
      textarea.onChange(e)
      saveComment(e.target.value, currentStop!)
    }
  }

  const notePlaceholder = useIntl().formatMessage({ id: 'app.addNote' })

  return (
    <RouteContainer>
      {stop?.optimized && (
        <Counter color={color}>{createStopCounter(index)}</Counter>
      )}
      <InputAndButtonContainer>
        <AutocompleteContainer>
          <PlacesAutocomplete
            readOnly={stop != null}
            index={index}
            onGeocode={handleSelected}
            description={stop?.formattedAddress}
            setEmptyStopVisible={setEmptyStopVisible}
          />
        </AutocompleteContainer>
        {isCommentOpen && (
          <CommentBox
            cols={1}
            onChange={(e) =>
              handleCommentChange(
                e as React.ChangeEvent<HTMLTextAreaElement>,
                stop!,
              )
            }
            placeholder={notePlaceholder}
            value={textarea.value}
          />
        )}
        {stop && (
          <CommentButton role="toggleCommentBox" onClick={toggleCommentBox}>
            <img src="images/comment.svg" alt="" />
          </CommentButton>
        )}
        <DeleteButton onClick={handleDelete} style={{ position: 'absolute' }}>
          ×
        </DeleteButton>
      </InputAndButtonContainer>
    </RouteContainer>
  )
}

export default RouteStop
