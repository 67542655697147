import styled from 'styled-components'
import tw from 'twin.macro'

const Main = styled.div`
  ${tw`
    sm:px-8
    px-8
    print:hidden
  `}
`

const Container = styled.div`
  ${tw`
    relative
    flex
    flex-wrap
    sm:shadow-lg
    shadow-none
  `}

  overflow: hidden;
  width: 100%;
`

export { Main, Container }
