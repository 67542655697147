import React from 'react'

import { Container } from './GoogleMapContainer.styled'
import GoogleMap from './GoogleMap/GoogleMap'
import Toggle from './Toggle/Toggle'

import type { FC } from 'react'

const GoogleMapContainer: FC = () => (
  <Container role="application">
    <GoogleMap />
    <Toggle />
  </Container>
)

export default GoogleMapContainer
