import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons'

import { Container, Link } from './SocialMedia.styled'

const SocialMedia: React.FC = () => (
  <Container>
    <Link href="https://www.facebook.com/speedyroute">
      <FontAwesomeIcon icon={faFacebook} />
    </Link>
    <Link href="https://twitter.com/speedyroute">
      <FontAwesomeIcon icon={faTwitter} />
    </Link>
    <Link href="https://instagram.com/speedyroute">
      <FontAwesomeIcon icon={faInstagram} />
    </Link>
    <Link href="https://www.pinterest.com/speedyroute">
      <FontAwesomeIcon icon={faPinterest} />
    </Link>
  </Container>
)

export default SocialMedia
