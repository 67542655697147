import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  useMaximumNumberOfVehicles,
  useStartStopState,
  useStopsState,
  useUserState,
} from '$/hooks'
import useRouteState from '$/hooks/useRouteState'
import type { Optional } from '$/types'
import { track } from '$/utils/amplitude'

import { Container } from './CalculateRoute.styled'
import { Button } from '../Button/Button.styled'
import { optimize } from '../../api/circuit/optimize'
import LoadingIcon from '../Icons/Loading'
import CalculateOptions from './CalculateOptions'
import ConfirmDialog from '../Dialog/Confirm'

interface Props {
  setEmptyStopVisible: (visible: boolean) => void
}

const CalculateRoute: React.FC<Props> = ({ setEmptyStopVisible }) => {
  const route = useRouteState()
  const stops = useStopsState()
  const user = useUserState()
  const intl = useIntl()
  const maximumNumberOfVehicles = useMaximumNumberOfVehicles()
  const startStop = useStartStopState()
  const [error, setError] = useState<
    Optional<{
      title: string
      description: string
    }>
  >(null)

  const [isOptimizing, setOptimizing] = useState(false)

  useEffect(() => {
    if (
      isOptimizing &&
      (route!.data.state.optimization === 'optimized' ||
        route!.data.state.optimization === 'creating')
    ) {
      setOptimizing(false)
    }
  }, [route!.data.state?.optimization])

  const routeRef = route!.ref!

  const onSuccessfulOptimize = () => {
    track('Optimized route', {
      'Number of stops': stops.size,
      'Has start location': Boolean(startStop?.doc),
    })
  }

  const onFailedOptimize = () => {
    setError({
      title: intl.formatMessage({
        id: 'app.genericOptimzationErrorDialogTitle',
      }),
      description: intl.formatMessage({
        id: 'app.genericOptimzationErrorDialogDescription',
      }),
    })
    setOptimizing(false)
    track('Failed to optimize route', {
      'Number of stops': stops.size,
      'Has start location': Boolean(startStop?.doc),
    })
  }

  const handleClick = async () => {
    if (startStop?.doc == null) {
      setError({
        title: intl.formatMessage({ id: 'app.addStartLocationDialogTitle' }),
        description: intl.formatMessage({
          id: 'app.addStartLocationDialogBody',
        }),
      })

      return
    }

    setOptimizing(true)

    try {
      await optimize(user, routeRef, startStop, maximumNumberOfVehicles)
      // No empty routes visibile after optimization
      setEmptyStopVisible(false)
      // Set focus to itself after optimization
      onSuccessfulOptimize()
    } catch (e) {
      setEmptyStopVisible(true)
      onFailedOptimize()
    }
  }

  return (
    <Container>
      {error && (
        <ConfirmDialog
          title={error.title}
          text={error.description}
          // Reset error on confirm
          onConfirm={() => setError(null)}
        />
      )}
      <Button onClick={handleClick}>
        {isOptimizing && <LoadingIcon />}
        <FormattedMessage id="app.optimizeRoute" />
      </Button>
      <CalculateOptions setEmptyStopVisible={setEmptyStopVisible} />
    </Container>
  )
}

export default CalculateRoute
