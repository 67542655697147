import React from 'react'

import { DropdownMenu, DropdownMenuItem } from './Dropdown.styled'

interface DropdownProps {
  position: 'toBottom' | 'toTop'
  children: React.ReactNode
}

export const Dropdown: React.FC<DropdownProps> = ({ position, children }) => {
  return (
    <DropdownMenu
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
      position={position}
    >
      {children}
    </DropdownMenu>
  )
}

interface DropdownItemProps {
  text: string
  icon?: React.ReactElement
  onClick: (event?: React.MouseEvent<HTMLAnchorElement>) => void
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  text,
  icon,
  onClick,
}) => {
  const onClickMenu = (event: React.MouseEvent<any>) => {
    event.preventDefault()
    event.stopPropagation()
    onClick(event)
  }

  return (
    <DropdownMenuItem
      role="menuitem"
      withIcon={icon != null}
      onClick={onClickMenu}
    >
      {icon}
      {text}
    </DropdownMenuItem>
  )
}
