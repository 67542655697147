import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Container, Title } from './About.styled'

const About: React.FC = () => (
  <Container id="about">
    <Title>
      <FormattedMessage id="page.about.title" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph1" />
    </p>
    <p>
      <FormattedMessage id="page.about.paragraph2" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle1" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph3" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle4" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph6" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle5" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph8" />
    </p>
    <p>
      <FormattedMessage id="page.about.paragraph9b" />
      <br />
      <FormattedMessage id="page.about.paragraph9c" />
    </p>
    <p>
      <a href="/pricing" rel="noreferrer noopener">
        <FormattedMessage id="page.about.paragraph9d" />
      </a>
    </p>
    <p>
      <FormattedMessage id="page.about.paragraph9e" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle6" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph11" />
    </p>
    <p>
      <FormattedMessage id="page.about.paragraph12" />
    </p>
    <p>
      <FormattedMessage id="page.about.paragraph13" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle7" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph14" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle8" />
    </Title>
    <p>
      <FormattedMessage id="page.about.paragraph15" />
    </p>
    <Title>
      <FormattedMessage id="page.about.subtitle10" />
    </Title>
    <p>
      <FormattedMessage
        id="page.about.paragraph17"
        values={{
          email: (
            <a href="mailto:support@speedyroute.com">support@speedyroute.com</a>
          ),
        }}
      />
    </p>
  </Container>
)

export default About
