import React, { useEffect, useState } from 'react'

import {
  CounterContainer,
  CounterResult,
  CounterController,
} from './Counter.styled'

interface DialogProps {
  value: number
  min: number
  max: number
  onCountChangeCallback: (inputValue: string) => void
}

const CounterInput: React.FC<DialogProps> = ({
  value,
  min,
  max,
  onCountChangeCallback,
}) => {
  const [count, setCount] = useState(value)

  useEffect(() => {
    setCount(value)
  }, [value])

  const decrement = () => {
    const newCount = count - 1

    setCount(newCount)
    onCountChangeCallback(newCount.toString())

    // We don't return earlier because we want
    // the callback to fire
    if (count <= min) {
      setCount(count)
    }
  }

  const increment = () => {
    const newCount = count + 1

    setCount(newCount)
    onCountChangeCallback(newCount.toString())

    // We don't return earlier because we want
    // the callback to fire
    if (count >= max) {
      setCount(count)
    }
  }

  const handleBlur = () => {
    let num = Number(value)

    num = num > max ? max : num
    num = num < min ? min : num

    if (Number.isNaN(num) === false) {
      setCount(num)
    }
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(event.target.value)

    if (Number.isNaN(num) === false) {
      setCount(Number(event.target.value))
      onCountChangeCallback(count.toString())
    }
  }

  return (
    <CounterContainer>
      <CounterResult
        role="maximum-number-of-vehicles"
        type="text"
        value={count}
        onChange={handleChangeInput}
        onBlur={handleBlur}
      />
      <CounterController onClick={decrement} disabled={value <= min}>
        &#8722;
      </CounterController>
      <CounterController onClick={increment}>&#43;</CounterController>
    </CounterContainer>
  )
}

export default CounterInput
