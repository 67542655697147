import styled from 'styled-components'
import tw from 'twin.macro'

export const ButtonStyled = styled.button`
  ${tw`
    border-gray-100
    border-0
    border-t-4
    border-b-4
    cursor-pointer
    inline
    float-left
    px-6
    py-2
    text-gray-400
    w-full

    focus:outline-none
    hover:bg-white
    hover:text-sr-600
    hover:font-semibold
  `}

  &:first-child {
    // We have to repeat identical properties in hover
    // because hovering on the parent unsets these properties
    // See components/GoogleMapContainer/Toggle/Toggle.styled.ts
    ${tw`
      bg-white
      border-l-4
      font-semibold
      rounded-l-lg
      text-sr-600

      hover:bg-white
      hover:font-semibold
      hover:text-sr-600
    `}
  }

  &:last-child {
    ${tw`
      border-r-4
      rounded-r-lg
    `}
  }
`
