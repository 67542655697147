import styled from 'styled-components'
import tw from 'twin.macro'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'

import { fadeInAnimation } from '$/css/keyframes'

const AutocompleteContainer = styled.div`
  flex: 1 0;
  ${tw`
    rounded-md
  `}
  &:hover,
  &:focus-within,
  &:hover input {
    ${tw`
      transition
      transition-colors
      duration-500
      ease-out
    `}
  }
`

// If we ever want the auto-complete dropdoown
// to be absolutely positioned, add "h-10" here
// and flexbox will overflow elements as needed.
const RouteContainer = styled.div`
  ${tw`
    flex
    items-start
    w-full
    mb-3
  `}
`

const Button = styled.button`
  ${tw`
    bg-gray-100
    border-none
    cursor-pointer
    flex
    flex-grow-0
    flex-shrink-0
    items-center
    justify-center
    ml-3
    rounded-md
    text-xl
    text-gray-400
    active:bg-gray-100
    focus:outline-none
    w-10
  `}
`

const Input = styled(ComboboxInput)`
  scroll-margin-top: 12rem;
  ${tw`
    border-0
    box-border
    rounded-md
    w-full
    h-10
    px-4
    border-none
    focus:outline-circuit
    focus:ring
    text-sm
    bg-gray-100
    placeholder-gray-400

    hover:bg-gray-200
    focus:bg-gray-200
  `}

  animation-name: ${fadeInAnimation};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
`

const List = styled(ComboboxList)`
  ${tw`
    bg-white
    shadow-lg
    w-full
    rounded-md
    shadow-lg
  `}

  &:empty {
    border: none;
  }
`

const NoResultsFound = styled.span`
  ${tw`
    block
    m-2
    text-sm
  `}
`

const Popover = styled(ComboboxPopover)`
  ${tw`
    border-none
    z-10
  `}
`

const Option = styled(ComboboxOption)`
  ${tw`
    appearance-none
    flex
    w-full
    px-4
    py-3
    text-sm
    leading-5
    text-gray-700
    hover:bg-gray-100
    hover:text-gray-900
    focus:outline-none
    focus:bg-gray-100
    focus:text-gray-900
    text-gray-900
  `}

  &:not(:last-child) {
    ${tw`
      border-b
      border-gray-200
    `}
  }

  &[aria-selected='true'],
  &[aria-selected='true']:hover,
  &:hover {
    ${tw`
      bg-gray-100
    `}
  }
`

export {
  AutocompleteContainer,
  Button,
  Combobox,
  Input as ComboboxInput,
  NoResultsFound,
  Popover as ComboboxPopover,
  List as ComboboxList,
  Option as ComboboxOption,
  RouteContainer,
}
