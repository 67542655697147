import React from 'react'

import {
  useDispatch,
  useGeolocationState,
  useRouteState,
  useStartStopState,
} from '$/hooks'
import type { Stop } from '$/types'

import * as StopAPI from '../../api/stop'
import * as GeolocationAPI from '../../api/geolocation'
import { ButtonAction } from '../Button/Button.styled'

import type { ReactElement } from 'react'

const Geolocation = (): ReactElement => {
  const dispatch = useDispatch()
  const geolocation = useGeolocationState()
  const route = useRouteState()
  const startStop = useStartStopState()

  const routeRef = route!.ref!

  const handleClick = async () => {
    const geocodeResult = await GeolocationAPI.get(geolocation!)
    const doc = {
      ...geocodeResult,
      type: 'start',
    }

    const { latitude, longitude } = doc
    const { id } = startStop!

    await StopAPI.save(routeRef!, doc as Stop, id!)

    dispatch({ type: 'UPDATE_MAP_CENTER', latitude, longitude })
  }

  return (
    <ButtonAction role="geolocation" onClick={handleClick}>
      <img src="images/geolocation.svg" alt="" />
    </ButtonAction>
  )
}

export default Geolocation
