import styled from 'styled-components'

import type { StylesConfig, Theme } from 'react-select'

export const DropdownArrow = styled.button`
  background-image: url(/images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: center center;
  float: right;
  height: 1rem; /* h-4 */
  width: 1rem; /* h-4 */
`

export const DropdownArrowIcon = styled(DropdownArrow.withComponent('div'))``

export const selectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'rgb(228,228,231)',
      bowShadow: 'none',
      border: 'none',
    },
    backgroundColor: state.menuIsOpen
      ? 'rgb(228,228,231)'
      : 'rgba(244,244,245)',
    border: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    cursor: 'pointer',
    fontSize: 14,
    outline: 'none',
    padding: '0 6px',
    height: '2.5rem', // H-10
    transitionProperty: 'background-color,border-color,color,fill,stroke', // Transition-colors
    transitionTimingFunction: 'cubic-bezier(0,0,0.2,1)', // Ease-out
    transitionDuration: '500ms', // Duration-500
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all 0.15s ease',
    // Toggle dropdown carat icon up/down
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: () => ({
    borderRadius: '4px',
    border: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    border: 'none',
    borderRadius: '0.375rem',
    backgroundColor: 'rgba(255,255,255,1)',
    boxShadow:
      '0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)',
  }),
  option: (provided) => ({
    ...provided,
    border: 'none',
    display: 'flex',
    width: '100%',
    appearance: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    background: 'white',
    color: 'rgba(74,85,104,1)',
    '&:hover': {
      bowShadow: 'none',
      border: 'none',
      background: '#F2F4F8',
      color: '#1E2022',
    },
    '&:focus': {
      outline: 'none',
      background: '#F2F4F8',
      color: '#1E2022',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(237,242,247,1)',
    },
  }),
}

export const theme = (theme: Theme): Theme => ({
  ...theme,
})
