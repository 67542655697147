import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useRouteState, useStopsState } from '$/hooks'
import {
  getUnoptimizedStops,
  getVehiclesWithStops,
} from '$/utils/stops/optimization'
import type { Stops } from '$/types'

import {
  Container,
  RouteName,
  Table,
  TableData,
  TableHead,
  TableRow,
} from './RoutePrint.styled'

const HeadText = ({ stops }: { stops: Stops }) => {
  const vehiclesWithStops = getVehiclesWithStops(stops)
  const hasRouteBeenOptimized = vehiclesWithStops.length > 0

  return hasRouteBeenOptimized ? (
    <>
      <FormattedMessage id="app.additionalStops" />{' '}
      <span>
        <FormattedMessage id="app.optimizeAgain" />
      </span>
    </>
  ) : (
    <>
      <FormattedMessage id="app.pleaseOptimizeRoute" />{' '}
      <span>
        <FormattedMessage id="app.pleaseOptimizeRouteAdvantages" />
      </span>
    </>
  )
}

const UnoptimizedStopsPrint = (): React.ReactElement | null => {
  const stops = useStopsState()
  const route = useRouteState()
  const unoptimizedStops = getUnoptimizedStops(stops).filter(([, doc]) =>
    Boolean(doc),
  )

  const vehiclesWithStops = getVehiclesWithStops(stops)

  // Don't render if the stops Map we want is empty
  if (unoptimizedStops.length === 0) {
    return null
  }

  return (
    <Container>
      {vehiclesWithStops.length > 1 && (
        <RouteName>{route!.data.title}</RouteName>
      )}
      <Table>
        <thead>
          <tr>
            <TableHead>
              <HeadText stops={stops} />
            </TableHead>
          </tr>
        </thead>

        <tbody>
          {unoptimizedStops.map(([, stop], index) => (
            <TableRow key={`print-optimized-stop-${stop!.placeId}-${index}`}>
              <TableData>{String(index + 1).padStart(2, '0')}</TableData>
              <TableData>
                <p>
                  <b>{stop!.addressLineOne}</b> {stop!.addressLineTwo}
                </p>
                {stop!.notes && <p>{stop!.notes}</p>}
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default UnoptimizedStopsPrint
