import styled from 'styled-components'
import tw from 'twin.macro'

import { RouteContainer } from '../PlacesAutocomplete/PlacesAutocomplete.styled'

export const StartStopContainer = styled(RouteContainer)`
  ${tw`
    items-stretch
  `}
`
