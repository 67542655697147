import { useState } from 'react'
import { useDebounce } from 'react-use'

import { useStartStopState, useStopsState } from '$/hooks'
import { search as searchConfig } from '$/config'
import type { Stop } from '$/types'

import * as AddressesAPI from '../../api/circuit/addresses'

const { shortDebounceDelay, longDebounceDelay } = searchConfig

export const useSearch = (term: string): Stop[] | undefined => {
  const stops = useStopsState()
  const startStop = useStartStopState()
  const [results, setResults] = useState()
  const [searchTerm, setSearchTerm] = useState('')

  const runSearch = async (currentSearchTerm: string) => {
    if (searchTerm && searchTerm.trim() !== '') {
      const newResults = await AddressesAPI.search(
        startStop,
        stops,
        currentSearchTerm,
      )

      setResults(newResults as any)
    }
  }

  useDebounce(
    () => {
      if (searchTerm) {
        runSearch(searchTerm)
      }

      return () => {
        setSearchTerm('')
      }
    },
    searchTerm.length > 2 ? shortDebounceDelay : longDebounceDelay,
    [searchTerm],
  )

  if (searchTerm !== term && term.length > 0) {
    setSearchTerm(term)
  }

  return results
}
