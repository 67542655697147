import Papa from 'papaparse'

import type { Optional, Stop, Stops } from '$/types'

import { buildFormattedAddress } from '../stops/address'

interface CSVContents {
  'Stop #': number
  Address: string | undefined
  Notes: string
}

export const exportToCSV = (stops: Stops): void => {
  const data = [...stops.entries()]
    .filter(([, stop]: [string, Optional<Stop>]) => stop)
    .map(([, stop], index) => getStopDataForExport(index + 1, stop!))

  return createCSV('route.csv', data)
}

// PRIVATE

const getStopDataForExport = (stopNumber: number, stop: Stop) => ({
  'Stop #': stopNumber,
  Address: buildFormattedAddress(stop),
  Notes: stop.notes || '',
})

export const createCSV = (filename: string, contents: CSVContents[]) => {
  // Produce a file and download it by emulating a click
  const { document, URL } = window
  const anchor = document.createElement('a')

  const data = Papa.unparse(contents)

  anchor.download = filename
  anchor.href = URL.createObjectURL(new Blob([data]))

  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
}
