import axios from 'axios'

import { apiBaseUrl } from '$/config'
import { buildFormattedAddress } from '$/utils/stops/address'
import { getCircuitHeaders } from '$/utils/circuit'
import type { StartStop, Stop, Stops } from '$/types'

import { getDefaultStopData } from '../defaultData'

export const search = async (
  startStop: StartStop,
  stops: Stops,
  query: string | undefined,
): Promise<Stop[]> => {
  const searchUrl = `${apiBaseUrl}/addresses/search`

  const locationStops = [...stops.values()]
    .filter((stop) => stop?.latitude && stop?.longitude)
    .map((stop) => {
      return {
        latitude: stop?.latitude,
        longitude: stop?.longitude,
      }
    })

  const startLocation = startStop
    ? {
        latitude: startStop?.doc?.latitude,
        longitude: startStop?.doc?.longitude,
      }
    : null

  const headers = await getCircuitHeaders()

  const result = await axios.post(
    searchUrl,
    {
      query,
      filterExistingStops: false,
      searchMode: 'suggested_only',
      locations: { start: startLocation, stops: locationStops },
    },
    {
      headers,
    },
  )

  const { suggestions } = result.data

  return suggestions.map(
    (searchResult: {
      addressLineOne: string
      addressLineTwo: string
      placeId: string
      placeTypes: string[]
      searchToken: string
    }) =>
      createStop({
        addressLineOne: searchResult.addressLineOne,
        addressLineTwo: searchResult.addressLineTwo,
        latitude: -1,
        longitude: -1,
        placeId: searchResult.placeId,
        placeTypes: searchResult.placeTypes,
        searchToken: searchResult.searchToken,
      } as StopData),
  )
}

const createStop = ({
  addressLineOne,
  addressLineTwo,
  latitude,
  longitude,
  placeId,
  searchToken,
  placeTypes,
}: StopData): Stop => ({
  ...getDefaultStopData(),
  addressLineOne,
  addressLineTwo,
  formattedAddress: buildFormattedAddress({ addressLineOne, addressLineTwo }),
  latitude,
  longitude,
  placeId,
  placeTypes,
  searchToken,
  addedTime: Date.now() / 1000,
})

interface StopData extends Stop {
  types: string[]
}
