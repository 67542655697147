import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  useDispatch,
  useGeolocationState,
  useRouteState,
  useStartStopState,
} from '$/hooks'
import type { ApiGeocodeResult, Stop } from '$/types'
import { track } from '$/utils/amplitude'

import * as StopAPI from '../../api/stop'
import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete'
import { FormHeader, Section } from '../Form/Form.styled'
import { AutocompleteContainer } from '../PlacesAutocomplete/PlacesAutocomplete.styled'
import { StartStopContainer } from './StartStop.styled'
import Geolocation from '../Geolocation/Geolocation'

const StartStop: React.FC = () => {
  const dispatch = useDispatch()
  const geolocation = useGeolocationState()
  const route = useRouteState()
  const startStop = useStartStopState()

  const routeRef = route!.ref!

  const handleSelected = async (geocodeResult: ApiGeocodeResult) => {
    const doc = {
      ...geocodeResult,
      type: 'start',
    }

    const { latitude, longitude } = doc

    dispatch({ type: 'UPDATE_MAP_CENTER', latitude, longitude })

    track('Set start location')
    await StopAPI.save(routeRef!, doc as Stop, startStop!.id!)
  }

  const handleClear = async () => {
    if (startStop?.doc) {
      await StopAPI.remove(route, startStop.doc!, startStop!.id!)
    }
  }

  return (
    <Section>
      <FormHeader>
        <FormattedMessage id="app.startLocation" />
      </FormHeader>
      <StartStopContainer>
        <AutocompleteContainer>
          <PlacesAutocomplete
            index={-1}
            description={startStop?.doc?.formattedAddress}
            onClear={handleClear}
            onGeocode={handleSelected}
            placeholder="app.setStartLocation"
          />
        </AutocompleteContainer>
        {geolocation && <Geolocation />}
      </StartStopContainer>
    </Section>
  )
}

export default StartStop
