import styled from 'styled-components'
import tw from 'twin.macro'
import TextareaAutosize from 'react-autosize-textarea'

import { ButtonAction } from '../Button/Button.styled'

const CommentBox = styled(TextareaAutosize)`
  ${tw`
    border-0
    border-gray-300
    border-t
    box-border
    font-body
    leading-5
    px-4
    py-3
    resize-none
    rounded
    w-full
    z-2
    rounded-t-none
    text-sm
    bg-gray-100

    focus:shadow-none
    focus:outline-none
  `}
  flex-basis: 100%;
`

const CommentButton = styled(ButtonAction)`
  ${tw`
    bg-gray-100
    absolute
    top-0
    right-10
    flex-grow-0
    flex-shrink-0
    pl-3
    w-7
  `}

  img {
    ${tw`
      w-4
      mt-1
    `}
  }
`

interface CounterProps {
  color: string
}

const Counter = styled.div<CounterProps>`
  ${tw`
    bg-white
    flex
    flex-grow-0
    flex-shrink-0
    items-center
    w-8
    h-10
    justify-start
    text-base
    text-sr-500
    font-heading
    font-bold
  `}
  color: ${(p) => p.color || '#000'};
`

const DeleteButton = styled(ButtonAction)`
  ${tw`
    absolute
    top-0
    right-0
    bottom-0
    w-10
    h-10
    text-gray-500
    bg-gray-100
  `}
`

const InputAndButtonContainer = styled.div`
  ${tw`
    relative
    bg-gray-100
    flex
    flex-grow
    flex-shrink-0
    flex-wrap
    rounded-md
    transition
    transition-colors
    duration-500
    ease-out
    bg-gray-100
    focus-within:outline-circuit
    focus-within:ring
  `}

  &:hover,
  &:focus-within,
  &:hover button {
    ${tw`
      bg-gray-200
    `}
  }

  button {
    background: inherit !important;
    transition: none;
  }

  /* InputAndButtonContainer controls
     all focus and hover state of its children */

  input,
  textarea,
  div,
  input:focus,
  textarea:focus,
  div:focus,
  input:hover,
  textarea:hover,
  div:hover {
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`

export {
  CommentBox,
  CommentButton,
  Counter,
  DeleteButton,
  InputAndButtonContainer,
}
