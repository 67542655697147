import { auth } from '$/components/Firebase'

import type firebase from 'firebase/app'

const waitForUser = async (): Promise<firebase.User | null> => {
  const timeoutPromise = new Promise<never>((res) =>
    setTimeout(res, 4000),
  ).then(() => {
    console.warn('Timed out waiting for user')

    return null
  })

  const authPromise = new Promise<firebase.User | null>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      resolve(user)
      unsubscribe()
    })
  })

  return Promise.race([timeoutPromise, authPromise])
}

export const getCircuitHeaders = async (): Promise<Record<string, string>> => {
  const currentUser = auth.currentUser ?? (await waitForUser())
  const token = await currentUser?.getIdToken(false)

  const headers: Record<string, string> = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}
