import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { buildFormattedAddress } from '$/utils/stops/address'
import {
  useDispatch,
  useRouteState,
  useStopsState,
  useSubscriptionState,
  useMaximumNumberOfVehicles,
  useStartStopState,
  useFocus,
} from '$/hooks'
import type { ApiGeocodeResult, Stop } from '$/types'
import { getUnoptimizedStops } from '$/utils/stops/optimization'

import { useSearch } from './useSearch'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  NoResultsFound,
} from './PlacesAutocomplete.styled'
import * as GeolocationAPI from '../../api/geolocation'
import { getNearestFreeStopIndex, maybeAddEmptyStop } from './maybeAddEmptyStop'

interface PlacesAutocompleteProps {
  index: number
  description?: string
  onClear?: () => void
  onGeocode?: (geoCodeResult: ApiGeocodeResult) => void
  placeholder?: string
  readOnly?: boolean
  setEmptyStopVisible?: (visible: boolean) => void
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  index,
  description = '',
  onClear = () => {},
  onGeocode = () => {},
  placeholder,
  readOnly,
  setEmptyStopVisible,
}: PlacesAutocompleteProps) => {
  const stops = useStopsState()
  const dispatch = useDispatch()
  const route = useRouteState()
  const subscription = useSubscriptionState()
  const routeRef = route!.ref!
  const numberOfDrivers = useMaximumNumberOfVehicles()

  const [inputRef, setInputFocus] = useFocus()
  const [searchTerm, setSearchTerm] = useState('')
  const results = useSearch(searchTerm)
  const startStop = useStartStopState()

  const addEmptyStopOptions = {
    dispatch,
    stops,
    subscription,
    routeRef,
    numberOfDrivers,
  }

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target

    if (value === '') {
      onClear()
    }

    setSearchTerm(value)
    if (setEmptyStopVisible) setEmptyStopVisible(true)
  }

  // ComponentDidMount
  useEffect(() => {
    if (
      // This component is within a loop and represent every stop we have.
      // Ensure we apply this for just one element.
      index === getUnoptimizedStops(stops).length &&
      // If all unoptimized stops are filled,
      // add a blank one at startup time. This gurarantees
      // we always have a free stop users can fill
      getNearestFreeStopIndex(stops) === -1
    ) {
      maybeAddEmptyStop(index, addEmptyStopOptions)
    }
  }, [])

  // ComponentDidUpdate
  useEffect(() => {
    if (
      // If startStop is empty we want it focused first
      // since the app doesn't work without a start
      index === -1 &&
      // Do not focus if stop is filled
      inputRef.current.value === '' &&
      // Only change focus if start stop is also not filled
      startStop?.doc == null
    ) {
      setInputFocus()
    }
  }, [startStop?.doc, stops])

  // ComponentDidUpdate
  useEffect(() => {
    // We always set focus on the first free
    // unoptimized stop. This allow us to control
    // user flow and always set focus to the "next" input
    const nearestFreeInputIndex = getNearestFreeStopIndex(stops)

    if (
      // We don't want auto focus happening when the start
      // stop is null as it's handled by another call
      // listening for startStop changes (see `useEffect` above)
      startStop?.doc == null ||
      // Do not focus if stop is filled
      inputRef.current.value !== '' ||
      // We don't want auto focus happening when no free route is available
      // And we only want to auto focus on the nearest free stop.
      index !== nearestFreeInputIndex
    ) {
      return
    }

    setInputFocus()
  }, [stops])

  const handleSelect = async (token: string) => {
    maybeAddEmptyStop(index, addEmptyStopOptions)

    const geocodeResult = await GeolocationAPI.geocode({
      stops,
      searchToken: token,
    })

    onGeocode(geocodeResult)
    setSearchTerm('')
  }

  return (
    <Combobox onSelect={handleSelect} aria-labelledby="places-autocomplete">
      <ComboboxInput
        ref={inputRef}
        readOnly={readOnly}
        autocomplete={false}
        disabled={false}
        onChange={handleSearchTermChange}
        placeholder={useIntl().formatMessage({
          id: placeholder ?? 'app.addLocationToVisit',
        })}
        value={searchTerm || description}
      />
      <Popover searchTerm={searchTerm} results={results} />
    </Combobox>
  )
}

const Popover = ({
  searchTerm,
  results,
}: {
  searchTerm: string
  results: Stop[] | undefined
}) => {
  return searchTerm !== '' && results ? (
    <ComboboxPopover>
      {results.length > 0 ? (
        <ComboboxList>
          {results.map(
            ({
              addressLineOne,
              addressLineTwo,
              searchToken,
            }: {
              addressLineOne: string
              addressLineTwo: string
              searchToken: string
            }) => {
              return (
                <ComboboxOption key={searchToken} value={searchToken}>
                  {buildFormattedAddress({ addressLineOne, addressLineTwo })}
                </ComboboxOption>
              )
            },
          )}
        </ComboboxList>
      ) : (
        <NoResultsFound>
          <FormattedMessage id="app.noResultsFound" />
        </NoResultsFound>
      )}
    </ComboboxPopover>
  ) : null
}

export default PlacesAutocomplete
