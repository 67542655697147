import React, { useEffect } from 'react'

import { Main, Container } from '$/theme/pages/index.styled'
import Controls from '$/components/Controls/Controls'
import SocialMedia from '$/components/SocialMedia/SocialMedia'
import About from '$/components/About/About'
import GoogleMapContainer from '$/components/GoogleMapContainer/GoogleMapContainer'
import RoutePrint from '$/components/Print/RoutePrint/RoutePrint'

import { useDispatch } from '../hooks'

const Index: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const success = ({ coords: { latitude, longitude } }: any) => {
      dispatch({ type: 'UPDATE_GEOLOCATION_POSITION', latitude, longitude })
    }

    const error = (reason: any) => {
      console.error(reason)
    }

    navigator.geolocation.getCurrentPosition(success, error)
  }, [])

  return (
    <>
      <Main>
        <Container>
          <Controls />
          <GoogleMapContainer />
        </Container>
        <SocialMedia />
        <About />
      </Main>
      <RoutePrint />
    </>
  )
}

export default Index
