import styled, { css } from 'styled-components'
import tw from 'twin.macro'

interface DropdownMenuProps {
  position: 'toBottom' | 'toTop'
}

export const DropdownMenu = styled.div<DropdownMenuProps>`
  ${tw`
    z-10
    origin-top-right
    absolute
    w-full
    mt-2
    rounded-md
    bg-white
    shadow-lg
  `}

  ${(p) =>
    p.position === 'toBottom' &&
    css`
      ${tw`
        top-8
        right-0
      `}
    `}

  ${(p) =>
    p.position === 'toTop' &&
    css`
      ${tw`
        absolute
        bottom-14
        w-full

        sm:bottom-20
        sm:w-fullMinus4rem
      `}
    `}
`

interface DropdownMenuItemProps {
  withIcon: boolean
}

export const DropdownMenuItem = styled.a.attrs({
  'data-item': 'dropdown',
})<DropdownMenuItemProps>`
  ${tw`
    appearance-none
    flex
    items-center
    w-full
    px-4
    py-3
    text-sm
    leading-5
    text-gray-700
    cursor-pointer

    hover:bg-gray-100
    hover:text-gray-900
    focus:outline-none
    focus:bg-gray-100
    focus:text-gray-900
  `}

  ${(p) =>
    p.withIcon &&
    css`
      ${tw`
      px-0
      py-0
    `}

      & *:first-child {
        ${tw`
        w-12
        h-10
      `}
      }
    `}

  &:not(:last-child) {
    ${tw`
      border-b
      border-gray-200
    `}
  }
`
