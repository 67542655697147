import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDebounce } from 'react-use'

import { isMaxDriversLimitReached } from '$/utils/subscription'
import {
  useDispatch,
  useRouteState,
  useSubscriptionState,
  useMaximumNumberOfVehicles,
} from '$/hooks'
import { track } from '$/utils/amplitude'

import * as RouteAPI from '../../api/route'
import { FormHeader, Section } from '../Form/Form.styled'
import Counter from '../Form/Counter/Counter'

const MaximumNumberOfVehicles: React.FC = () => {
  const dispatch = useDispatch()
  const route = useRouteState()
  const subscription = useSubscriptionState()
  const drivers = useMaximumNumberOfVehicles()

  const routeRef = route!.ref!

  const [requestedNumberOfVehicles, setRequestedNumberofDrivers] = useState<
    undefined | number
  >()

  useDebounce(
    () => {
      if (!requestedNumberOfVehicles) return

      RouteAPI.update(routeRef!, {
        maximumNumberOfVehicles: String(requestedNumberOfVehicles),
      })
    },
    300,
    [requestedNumberOfVehicles],
  )

  const handleChange = (inputValue: string) => {
    const driverLimit = isMaxDriversLimitReached(
      Number(inputValue),
      subscription,
    )

    if (driverLimit) {
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'upgradeRequired' })
      track('Hit driver limit', { 'Driver limit': subscription.maxDrivers })
    } else {
      setRequestedNumberofDrivers(Number(inputValue))
      track('Changed driver count', { 'Driver count': Number(inputValue) })
    }
  }

  return (
    <Section>
      <FormHeader>
        <FormattedMessage id="app.maximumNumberVehicles" />
      </FormHeader>
      <Counter
        value={drivers}
        min={1}
        max={subscription.maxDrivers}
        onCountChangeCallback={handleChange}
      />
    </Section>
  )
}

export default MaximumNumberOfVehicles
