import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`
    absolute
    bg-gray-100
    flex
    left-0
    m-2
    rounded-md
    shadow-lg
    top-0
  `}

  :hover {
    > *:first-of-type {
      ${tw`
        bg-transparent
        font-normal
        text-gray-400
      `}
      *
    }
  }
`
