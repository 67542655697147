import React from 'react'

import { ButtonStyled } from './MapButton.styled'

interface Button {
  children: React.ReactNode
  handleClick: () => void
}

const Button: React.FC<Button> = ({ children, handleClick }: Button) => (
  <ButtonStyled onClick={handleClick}>{children}</ButtonStyled>
)

export default Button
