import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`
    relative
    h-screen
    sm:h-app
    w-full
    sm:w-4/6
    mt-12
    sm:mt-0
  `}
`
