import styled from 'styled-components'
import tw from 'twin.macro'

export const Link = styled.a.attrs({ target: '_blank' })`
  ${tw`
    cursor-pointer
    text-gray-500
    text-4xl

    hover:text-gray-400
  `}
`

export const Container = styled.div`
  ${tw`
    hidden
    sm:flex
    justify-end
    mt-6
    space-x-4
  `}
`
