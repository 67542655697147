import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`
    bg-white
    box-border
    flex
    relative
    w-full

    sm:absolute
    sm:bottom-0
    sm:left-0
    sm:p-6
    sm:px-8
    sm:z-10
    sm:w-2/6
    sm:shadow-lg
  `}
`

const Icon = styled.div`
  ${tw`
    bg-center
    bg-no-repeat
    float-right
    h-4
    w-4
  `}
`

export const EllipsisIcon = styled(Icon)`
  background-image: url(/images/icons/ellipsis-icon.svg);
`

export const ExportIcon = styled(Icon)`
  background-image: url(/images/icons/export-icon.svg);
`

export const PrintIcon = styled(Icon)`
  background-image: url(/images/icons/print.svg);
`

export const ResetIcon = styled(Icon)`
  background-image: url(/images/icons/delete-icon.svg);
`
