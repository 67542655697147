import axios from 'axios'

import { apiBaseUrl, isDev } from '$/config'
import { getCircuitHeaders } from '$/utils/circuit'
import type { StartStop, User } from '$/types'

import type { DocumentReference } from '@firebase/firestore-types'

const optimizeUrl = `${apiBaseUrl}/optimize`

const baseSettings = {
  project: isDev ? 'dev' : 'teams',
  optimizationType: 'restart',
}

export const optimize = async (
  user: User,
  routeRef: DocumentReference | undefined,
  startStop: StartStop,
  numberOfDrivers: number,
): Promise<void> => {
  if (!user) return
  if (!routeRef) return
  if (!startStop?.doc?.latitude) return

  const dynamicSettings = {
    userId: user!.uid,
    routeId: routeRef!.id,
    drivers: numberOfDrivers,
    locations: {
      current: {
        latitude: startStop!.doc!.latitude,
        longitude: startStop!.doc!.longitude,
      },
    },
  }

  const request = { ...baseSettings, ...dynamicSettings }
  const headers = await getCircuitHeaders()

  return axios.post(optimizeUrl, request, { headers })
}
