import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStopsState } from '$/hooks'
import {
  getUnoptimizedStops,
  optimizedStopsExist,
} from '$/utils/stops/optimization'

import { FormHeader } from '../Form/Form.styled'
import RouteStop from '../RouteStop/RouteStop'
import { getNearestFreeStopIndex } from '../PlacesAutocomplete/maybeAddEmptyStop'

interface Props {
  emptyStopVisible: boolean
  setEmptyStopVisible: (visible: boolean) => void
}

const UnoptimizedStops: React.FC<Props> = ({
  emptyStopVisible,
  setEmptyStopVisible,
}) => {
  const stops = useStopsState()
  const unoptimizedStops = getUnoptimizedStops(stops)

  useEffect(() => {
    // There are only two cases where we want the blank
    // input to show up: with an empty profile (no stops), or
    // when they click the "Add a location" button
    // if (
    //   unoptimizedStops.length === initialNumberOfStopInputs ||
    //   emptyStopVisible
    // ) {
    //   setEmptyStopVisible(true)
    // }

    // Always display blank input
    setEmptyStopVisible(true)
  }, [])

  useEffect(() => {
    if (emptyStopVisible) setEmptyStopVisible(emptyStopVisible)
  }, [stops])

  return (
    <>
      {emptyStopVisible && (
        <div>
          <FormHeader>
            {optimizedStopsExist(stops) ? (
              <FormattedMessage id="app.unoptimizedStops" />
            ) : (
              <FormattedMessage id="app.stops" />
            )}
          </FormHeader>
          {unoptimizedStops.map(([id, stop], index) => (
            <RouteStop
              freeStopExists={getNearestFreeStopIndex(stops) !== -1}
              id={id}
              index={index}
              key={id}
              stop={stop}
              color="#000"
              setEmptyStopVisible={setEmptyStopVisible}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default UnoptimizedStops
