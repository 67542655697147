import React from 'react'

import { useStopsState } from '$/hooks'
import { getVehiclesWithStops } from '$/utils/stops/optimization'

import OptimizedStopsPerVehicle from './OptimizedStopsPerVehicle'

const OptimizedStops: React.FC = () => {
  const stops = useStopsState()

  const vehicles = getVehiclesWithStops(stops)

  return (
    <>
      {vehicles.map((vehicleNumber, index) => {
        return (
          <OptimizedStopsPerVehicle
            key={`optimized-route-key-${index}`}
            stops={stops}
            vehicleNumber={vehicleNumber!}
          />
        )
      })}
    </>
  )
}

export default OptimizedStops
