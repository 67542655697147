import axios from 'axios'

import { apiBaseUrl, isDev } from '$/config'
import { buildFormattedAddress } from '$/utils/stops/address'
import { getCircuitHeaders } from '$/utils/circuit'
import type { ApiGeocodeResult, GeoCoords, Stop, Stops } from '$/types'

import { getDefaultStopData } from './defaultData'

interface GeocodeInterface {
  stops: Stops
  query?: string
  searchToken?: string
  placeId?: string
}

interface BatchGeocodeInterface extends GeocodeInterface {
  query?: never
  queries: string[]
}

const geocodeURL = `${apiBaseUrl}/addresses/geocode`

export const batchGeocode = async ({
  stops,
  queries,
}: BatchGeocodeInterface): Promise<ApiGeocodeResult[]> => {
  const newStops = new Array<ApiGeocodeResult>()

  await Promise.all(
    [...queries.values()].map(async (query) => {
      const stop: ApiGeocodeResult = await geocode({
        stops,
        query,
      })

      if (!stop) return
      newStops.push(stop)
    }),
  )

  return newStops.filter((stop) => stop?.latitude && stop?.longitude)
}

export const importStops = async (
  userId: string | undefined,
  routeId: string | undefined,
  queries: Array<Record<string, unknown>>,
): Promise<{ stops: Stop[] }> => {
  const importUrl = `${apiBaseUrl}/import`
  const headers = await getCircuitHeaders()

  const { data } = await axios.post(
    importUrl,
    {
      project: isDev ? 'dev' : 'teams',
      userId,
      routeId,
      queries,
    },
    { headers },
  )

  return data
}

export const geocode = async ({
  stops,
  query,
  searchToken,
  placeId,
}: GeocodeInterface): Promise<ApiGeocodeResult> => {
  const geocodeUrl = `${apiBaseUrl}/addresses/geocode`
  const headers = await getCircuitHeaders()

  const locationStops = [...stops.values()]
    .filter((stop) => stop?.latitude && stop?.longitude)
    .map((stop) => {
      return {
        latitude: stop?.latitude,
        longitude: stop?.longitude,
      }
    })

  const { data } = await axios.post(
    geocodeUrl,
    {
      query,
      searchToken,
      placeId,
      locations: { stops: locationStops },
    },
    { headers },
  )

  return createStop(data) as ApiGeocodeResult
}

export const get = async ({
  latitude,
  longitude,
}: GeoCoords): Promise<ApiGeocodeResult> => {
  return getGeocode(latitude, longitude)
}

// PRIVATE

const buildQuery = (latitude: number, longitude: number) =>
  `${latitude},${longitude}`

const getGeocode = async (latitude: number, longitude: number) => {
  const query = buildQuery(latitude, longitude)
  const headers = await getCircuitHeaders()

  const result = await axios.post(geocodeURL, { query }, { headers })

  return result.data
}

interface StopData extends Stop {
  placeTypes: string[]
}
const createStop = ({
  addressLineOne,
  addressLineTwo,
  latitude,
  longitude,
  placeId,
  placeTypes,
}: StopData): Stop => ({
  ...getDefaultStopData(),
  addressLineOne,
  addressLineTwo,
  formattedAddress: buildFormattedAddress({ addressLineOne, addressLineTwo }),
  latitude,
  longitude,
  placeId,
  placeTypes,
  addedTime: Date.now() / 1000,
  isGeocoded: true,
})
