import { isMaxStopsLimitReached } from '$/utils/subscription'
import { track } from '$/utils/amplitude'
import type { Stops, Subscription } from '$/types'
import { getUnoptimizedStops } from '$/utils/stops/optimization'

import type { DocumentReference } from '@firebase/firestore-types'

type DispatchStopOptions = {
  dispatch: React.Dispatch<any>
  numberOfDrivers: number
  attempt?: boolean
}

export function getNearestFreeStopIndex(stops: Stops) {
  return getUnoptimizedStops(stops).findIndex((stop) => stop[1] == null)
}

function dispatchStop(
  routeRef: DocumentReference,
  { dispatch, numberOfDrivers, attempt }: DispatchStopOptions,
): void {
  dispatch({ type: 'ADD_STOP', routeRef, numberOfDrivers, attempt })
  track('Added stop')
}

type MaybeAddEmptyStopOptions = {
  dispatch: React.Dispatch<any>
  stops: Stops
  subscription: Subscription
  routeRef: DocumentReference
  numberOfDrivers: number
  setEmptyStopVisible?: (visible: boolean) => void
}

export function maybeAddEmptyStop(
  index: number,
  {
    dispatch,
    stops,
    subscription,
    routeRef,
    numberOfDrivers,
    setEmptyStopVisible,
  }: MaybeAddEmptyStopOptions,
) {
  // Don't add a blank stop for start/end locations
  if (index === -1) return

  // Ask for upgrade if operation is forbidden
  if (isMaxStopsLimitReached(stops, subscription)) {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'upgradeRequired' })
    track('Hit stop limit', { 'Stop limit': subscription.maxStops })

    return
  }

  const nearestFreeStop = getNearestFreeStopIndex(stops)
  const unoptimizedStopsSize = getUnoptimizedStops(stops).length
  const nextFreeStopIsLast =
    // We count less one as we always want to have at least
    // one free input available
    nearestFreeStop !== unoptimizedStopsSize - 1

  // If the next free stop is also the last input,
  // we focus on it instead of creating a new one.
  // In this case we just abort and let <PlacesAutocomplete />
  // handle the auto focus on component update
  if (nextFreeStopIsLast) return

  // No free stops means we have to create one.
  // New stops are auto-focused at render time so we
  // don't need to do anything other than creating a stop
  if (setEmptyStopVisible) setEmptyStopVisible(true)
  dispatchStop(routeRef, { dispatch, numberOfDrivers })
}
