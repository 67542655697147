import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { isMaxStopsLimitReached } from '$/utils/subscription'
import {
  useDispatch,
  useDialogState,
  useOnClickOutside,
  useRouteState,
  useStopsState,
  useSubscriptionState,
  useMaximumNumberOfVehicles,
} from '$/hooks'
import { track } from '$/utils/amplitude'

import { SecondaryButton } from '../Button/Button.styled'
import FromSpreadsheet from './FromSpreadsheet/FromSpreadsheet'
import { BatchGeocodeSection } from '../Form/Form.styled'
import { Dropdown, DropdownItem } from '../Dropdown/Dropdown'
import BatchGeocodeDialog from './BatchGeocodeDialog'
import { getNearestFreeStopIndex } from '../PlacesAutocomplete/maybeAddEmptyStop'

interface Props {
  setEmptyStopVisible: (visible: boolean) => void
}

const StopCreators: React.FC<Props> = ({ setEmptyStopVisible }) => {
  const ref = useRef() as React.MutableRefObject<HTMLButtonElement>

  const dispatch = useDispatch()
  const dialogOpen = useDialogState()
  const subscription = useSubscriptionState()
  const route = useRouteState()
  const stops = useStopsState()
  const numberOfDrivers = useMaximumNumberOfVehicles()
  const routeRef = route!.ref!
  const [isOpen, setIsOpen] = useState(false)
  const [showBatchGeocode, setShowBatchGeocode] = useState(true)

  useOnClickOutside(ref, () => setIsOpen(false))

  useEffect(() => {
    setShowBatchGeocode(true)
  }, [stops.size])

  const handleToggleDropdown = () => setIsOpen(!isOpen)

  const onFocusAddLocation = () => {
    if (isMaxStopsLimitReached(stops, subscription)) {
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'upgradeRequired' })
      track('Hit stop limit', { 'Stop limit': subscription.maxStops })
    } else {
      // While there are free stop input indexes, dispatch a message
      // to the store as an "attempt". This will tell the reducer
      // that no action should be done other than sending an update
      // messsage to the UI.
      if (getNearestFreeStopIndex(stops) !== -1) {
        setEmptyStopVisible(true)
        dispatch({ type: 'ADD_STOP', routeRef, numberOfDrivers, attempt: true })

        return
      }

      // Triggers state that fades out the element
      // Evaluates to true again at each new render.
      // See useEffect() call above.
      setShowBatchGeocode(false)

      dispatch({ type: 'ADD_STOP', routeRef, numberOfDrivers })
      track('Added stop')
    }
  }

  const handleClickImportFromTxt = () => {
    dispatch({
      type: 'UPDATE_OPEN_DIALOG',
      openDialog: 'pasteMultipleLocations',
    })
  }

  const importFromTextLabel = useIntl().formatMessage({ id: 'app.pasteList' })

  return (
    <BatchGeocodeSection ref={ref} hidden={!showBatchGeocode}>
      {dialogOpen !== 'none' && <BatchGeocodeDialog />}
      {isOpen && (
        <Dropdown position="toBottom">
          <DropdownItem
            onClick={handleClickImportFromTxt}
            text={importFromTextLabel}
          />

          <FromSpreadsheet setIsOpen={setIsOpen} />
        </Dropdown>
      )}
      <div className="grid-cols-2 grid w-full space-x-4 mr-4">
        <SecondaryButton onClick={onFocusAddLocation}>
          <FormattedMessage id="app.addAnotherLocation" />
        </SecondaryButton>
        <SecondaryButton
          ref={ref}
          onClick={handleToggleDropdown}
          role="toggle-batch-stop-creators"
        >
          <FormattedMessage id="app.importStops" />
        </SecondaryButton>
      </div>
    </BatchGeocodeSection>
  )
}

export default StopCreators
