import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useRouteState, useStopsState } from '$/hooks'
import {
  getOptimizedStops,
  getDriverColor,
  getStopSummary,
} from '$/utils/stops/optimization'
import type { Stops } from '$/types'

import { CarIcon } from '../../shared/RouteStop/RouteStop.styled'
import {
  Container,
  RouteName,
  Table,
  TableData,
  TableHead,
  TableRow,
} from './RoutePrint.styled'

interface HeadTitleProps {
  vehicleNumber: number
  vehicles: number
  stops: Stops
}

const HeadTitle = ({ stops, vehicleNumber, vehicles }: HeadTitleProps) => {
  const optimizedStops = getOptimizedStops(stops, vehicleNumber)
  const driverColor = getDriverColor(optimizedStops[0][1])

  return (
    <>
      {vehicles === 1 ? (
        <FormattedMessage id="app.totalTrip" />
      ) : (
        <>
          <CarIcon color={driverColor} />
          <b style={{ color: driverColor }}>
            <FormattedMessage
              id="app.vehicleNumber"
              values={{ vehicleNumber: vehicleNumber + 1 }}
            />
          </b>
        </>
      )}
    </>
  )
}

interface OptimizedStopsPerVehiclePrintProps {
  vehicleNumber: number
  vehicles: number
}

const OptimizedStopsPerVehiclePrint: React.FC<
  OptimizedStopsPerVehiclePrintProps
> = ({ vehicleNumber, vehicles }) => {
  const stops = useStopsState()
  const route = useRouteState()

  return (
    <Container skipPageAfter={vehicles > 1}>
      <RouteName>{route!.data.title}</RouteName>
      <Table>
        <thead>
          <tr>
            <TableHead>
              <div>
                <HeadTitle
                  stops={stops}
                  vehicleNumber={vehicleNumber}
                  vehicles={vehicles}
                />
                <span>{getStopSummary(stops, vehicleNumber)}</span>
              </div>
            </TableHead>
          </tr>
        </thead>

        <tbody>
          {getOptimizedStops(stops, vehicleNumber).map(([, stop], index) => (
            <TableRow key={`print-unoptimized-stop-${index}`}>
              <TableData>{String(index + 1).padStart(2, '0')}</TableData>
              <TableData>
                <p>
                  <b>{stop!.addressLineOne}</b> {stop!.addressLineTwo}
                </p>
                {stop!.notes && <p>{stop!.notes}</p>}
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default OptimizedStopsPerVehiclePrint
