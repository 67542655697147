import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useRouteState, useDispatch, useEndStopState } from '$/hooks'
import type { ApiGeocodeResult, EndStopPreference, Stop } from '$/types'
import { track } from '$/utils/amplitude'

import * as RouteAPI from '../../api/route'
import * as StopAPI from '../../api/stop'
import { useOptions, getOption } from './useOptions'
import Select from '../Form/Select/Select'
import { Section, FormHeader } from '../Form/Form.styled'
import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete'
import { PlacesAutocompleteContainer } from './EndStop.styled'

const EndStop: React.FC = () => {
  const dispatch = useDispatch()
  const endStop = useEndStopState()
  const options = useOptions()
  const route = useRouteState()

  const routeRef = route!.ref!

  const handleChange = async ({
    value: preference,
  }: {
    value: EndStopPreference
  }) => {
    dispatch({ type: 'UPDATE_END_STOP_PREFERENCE', preference })
    dispatch({ type: 'CLEAR_END_STOP_ADDRESS' })

    RouteAPI.update(routeRef!, { endStopPreference: preference })

    if (endStop.doc) {
      await StopAPI.remove(route, endStop.doc!, endStop.id!)
    }
  }

  const handleClear = async () => {
    if (endStop.doc) {
      await StopAPI.remove(route, endStop.doc!, endStop.id!)
    }
  }

  const handleSelected = async (geocodeResult: ApiGeocodeResult) => {
    const doc = {
      ...geocodeResult,
      type: 'end',
    }

    await StopAPI.save(route!.ref, doc as Stop, endStop.id!)
    const { latitude, longitude } = doc

    dispatch({ type: 'UPDATE_MAP_CENTER', latitude, longitude })
    track('Set end location')
  }

  const isCustomSelected = endStop.preference === 'custom'

  return (
    <Section>
      <FormHeader>
        <FormattedMessage id="app.finishLocation" />
      </FormHeader>

      <Select
        defaultValue={options[1]}
        onChange={handleChange}
        options={options}
        value={getOption(endStop.preference)}
      />

      {isCustomSelected && (
        <PlacesAutocompleteContainer>
          <PlacesAutocomplete
            index={-1}
            description={endStop?.doc?.formattedAddress}
            onClear={handleClear}
            onGeocode={handleSelected}
          />
        </PlacesAutocompleteContainer>
      )}
    </Section>
  )
}

export default EndStop
