import styled from 'styled-components'
import tw from 'twin.macro'

interface CarIconProps {
  color: string
}

export const CarIcon = styled.div<CarIconProps>`
  background-color: ${(p) => p.color || 'inherit'};
  -webkit-mask-image: url(/images/icons/car-icon.svg);
  mask-image: url(/images/icons/car-icon.svg);
  mask-repeat: no-repeat;
  mask-position: center center;

  ${tw`
    mr-4
    w-6
    h-6
  `}
`
