import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`
    text-gray-800
    w-full
    pt-14

    sm:w-1/2
  `}

  p {
    ${tw`
      leading-relaxed
      text-base
    `}
  }
`

export const Title = styled.h4`
  ${tw`
    text-lg
  `}
`
