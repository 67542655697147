import React from 'react'

import { Container } from './Toggle.styled'
import Button from '../../Button/MapButton/MapButton'

import type { FC } from 'react'

const Toggle: FC = () => (
  <Container>
    <Button handleClick={() => {}}>Map</Button>
  </Container>
)

export default Toggle
