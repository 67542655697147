import { useIntl } from 'react-intl'

import type { EndStopPreference } from '$/types'

export const useOptions = (): Array<Record<string, string>> => [
  // Todo: Temporarily removed until we can consider properly 'return to start' in /optimize
  // {
  //   value: 'return',
  //   label: useIntl().formatMessage({id: 'app.returnToStartStop'}),
  // },
  { value: 'none', label: useIntl().formatMessage({ id: 'app.none' }) },
  {
    value: 'custom',
    label: useIntl().formatMessage({ id: 'app.customAddress' }),
  },
]

export const getOption = (
  preference: EndStopPreference,
): Array<Record<string, string>> =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useOptions().filter((option) => option.value === preference)
