import React, { useState } from 'react'

import { useInitializationState } from '$/hooks'

import { Container } from './Controls.styled'
import StartStop from '../StartStop/StartStop'
import MaximumNumberOfVehicles from '../MaximumNumberOfVehicles/MaximumNumberOfVehicles'
import { MainSection } from '../Form/Form.styled'
import StopCreators from '../StopCreators/StopCreators'
import UnoptimizedStops from '../RouteStops/UnoptimizedStops'
import OptimizedStops from '../RouteStops/OptimizedStops'
import EndStop from '../EndStop/EndStop'
import CalculateRoute from '../CalculateRoute/CalculateRoute'
import { Loading } from '../shared/Loading/Loading.styled'

const Controls: React.FC = () => {
  const isAppInitialized = useInitializationState()

  const [emptyStopVisible, setEmptyStopVisible] = useState(false)

  if (!isAppInitialized) {
    return (
      <Container loading="loading">
        <Loading />
      </Container>
    )
  }

  return (
    <Container>
      <StartStop />
      <EndStop />
      <MaximumNumberOfVehicles />
      <MainSection>
        <OptimizedStops />
        <UnoptimizedStops
          emptyStopVisible={emptyStopVisible}
          setEmptyStopVisible={setEmptyStopVisible}
        />
        <StopCreators setEmptyStopVisible={setEmptyStopVisible} />
      </MainSection>
      <CalculateRoute setEmptyStopVisible={setEmptyStopVisible} />
      <div className="h-24" />
    </Container>
  )
}

export default Controls
