import styled from 'styled-components'
import tw from 'twin.macro'

import { Input } from '../Form.styled'
import { ButtonAction } from '../../Button/Button.styled'

export const CounterContainer = styled.div`
  ${tw`
    flex
    items-center
  `}
`

export const CounterResult = styled(Input)`
  ${tw`
    bg-gray-100
    transition
    transition-colors
    duration-500
    ease-out
    border-0
    box-border
    rounded-md
    w-full
    h-10
    px-4
    text-sm
    placeholder-gray-400

    hover:bg-gray-200
    focus:bg-gray-200
    focus:outline-none
  `}
`

export const CounterController = styled(ButtonAction)`
  ${tw`
    bg-gray-100
    transition
    transition-colors
    duration-500
    ease-out
  `}
`
