import React, { useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import LRUCache from 'lru-cache'
import { useDebounce } from 'react-use'

import {
  useEndStopState,
  useMaximumNumberOfVehicles,
  useStartStopState,
  useStopsState,
} from '$/hooks'
import useRouteState from '$/hooks/useRouteState'
import type { MapMarker, Optional, Stops, Stop, RouteState } from '$/types'
import { getDriverColor } from '$/utils/stops/optimization'

const activeMarkers: LRUCache<string, MapMarker> = new LRUCache({
  noDisposeOnSet: true,
  dispose(_key: string, marker: MapMarker) {
    marker.setMap(null)
  },
})

const activePolylines = new LRUCache({
  dispose(_key: string, polyline: google.maps.Polyline) {
    polyline.setMap(null)
  },
})

const createIdFromStop = (
  stopNumber?: number,
  polylineString?: string,
  driverNumber?: number,
  isOptimized?: boolean,
) => `${stopNumber}-${polylineString}-${driverNumber}-${isOptimized}`

const shouldRenderEntireMap = (route: RouteState) =>
  route?.data?.state.optimization === 'optimized'

let map: google.maps.Map
let maps: google.maps.Map

const GoogleMap = () => {
  const route = useRouteState()
  const startStop = useStartStopState()
  const endStop = useEndStopState()
  const stops = useStopsState()
  const numberOfDrivers = useMaximumNumberOfVehicles()

  useDebounce(
    () => {
      shouldRenderMarkersAndPolylines() && renderMarkersAndPolylines()
    },
    250,
    [route, stops, numberOfDrivers],
  )

  useEffect(() => {
    positionMap()
  }, [stops])

  const shouldRenderMarkersAndPolylines = () =>
    route && stops && numberOfDrivers > -1

  const renderMarkersAndPolylines = () => {
    const stopValues = [...stops.values()]
    const stopDocs = stopValues.filter((stop) => stop)
    const newMarkerSize = getNewCacheSize(stopDocs, true, true)
    const newPolyLineSize = getNewCacheSize(
      stopDocs,
      false,
      numberOfDrivers === 1,
    )

    const increasingSize = activeMarkers.max < newMarkerSize

    if (increasingSize) {
      // If increasing size, increase cache before re-render
      activeMarkers.max = newMarkerSize
      activePolylines.max = newPolyLineSize
    }

    renderPolylines(stopDocs as Stop[])
    renderMarkers(stopDocs as Stop[])

    if (!increasingSize) {
      // If decreasing size, decrease cache after re-render, so unnused stop is removed
      activeMarkers.max = newMarkerSize
      activePolylines.max = newPolyLineSize
    }
  }

  const getNewCacheSize = (
    stopDocs: Array<Optional<Stop>>,
    includeStartStop: boolean,
    includeEndStop: boolean,
  ) => {
    let cacheSize = stopDocs.length

    if (includeStartStop && startStop?.doc) cacheSize++
    if (includeEndStop && endStop?.doc) cacheSize++

    return cacheSize
  }

  const handleGoogleApiLoaded = (
    thisMap: google.maps.Map,
    thisMaps: google.maps.Map,
  ) => {
    map = thisMap
    maps = thisMaps

    positionMap()
  }

  const createBounds = (stops: Stops) => {
    let addedStops = 0
    const bounds = new google.maps.LatLngBounds()

    if (stops?.size > 0) {
      const stopDocs = [...stops.values()]

      stopDocs
        .filter((stop) => stop)
        .forEach((stop) => {
          bounds.extend(new google.maps.LatLng(stop!.latitude, stop!.longitude))
          addedStops++
        })
    }

    if (startStop?.doc) {
      bounds.extend(
        new google.maps.LatLng(startStop.doc.latitude, startStop.doc.longitude),
      )
      addedStops++
    }

    if (endStop?.doc) {
      bounds.extend(
        new google.maps.LatLng(endStop.doc.latitude, endStop.doc.longitude),
      )
      addedStops++
    }

    if (addedStops === 1) {
      const padding = 0.005

      bounds.extend(
        new google.maps.LatLng(
          bounds.getCenter().lat() + padding,
          bounds.getCenter().lng() + padding,
        ),
      )

      bounds.extend(
        new google.maps.LatLng(
          bounds.getCenter().lat() - padding,
          bounds.getCenter().lng() - padding,
        ),
      )
    }

    return bounds
  }

  const renderPolylines = (stopDocs: Stop[]) => {
    if (!(map && maps)) return

    stopDocs.forEach((stop) => {
      stop && createOrGetPolyline(stop)
    })

    if (numberOfDrivers === 1 && endStop?.doc) {
      createOrGetPolyline(endStop.doc)
    }
  }

  const renderMarkers = (stopDocs: Stop[]) => {
    if (!map || !maps) return

    if (startStop?.doc) {
      createOrGetMarker(startStop.doc, -1)
    }

    if (endStop?.doc) {
      createOrGetMarker(endStop.doc, -2)
    }

    stopDocs.forEach((stop, index) => {
      stop && createOrGetMarker(stop, index + 1)
    })
  }

  const createOrGetPolyline = (stop: Stop) => {
    const { polyLineString } = stop

    if (!polyLineString) return

    let polyline
    const id = createIdFromStop(
      undefined,
      polyLineString,
      stop.localData?.driverNumber,
      shouldRenderEntireMap(route),
    )

    if (activePolylines.has(id)) {
      polyline = activePolylines.get(id)
    } else {
      const polyArray = google.maps.geometry.encoding.decodePath(polyLineString)

      if (!polyArray) return

      polyline = new google.maps.Polyline({
        path: shouldRenderEntireMap(route) ? polyArray : [],
        strokeColor: getDriverColor(stop),
        strokeOpacity: 1.0,
        strokeWeight: 4,
      })

      polyline.setMap(map)
      activePolylines.set(id, polyline)
    }

    return polyline
  }

  const createOrGetMarker = (stop: Stop, stopNumber: number) => {
    if (!stop) return null

    const id = createIdFromStop(
      stopNumber,
      undefined,
      stop.localData?.driverNumber,
      shouldRenderEntireMap(route),
    )

    const marker: MapMarker | undefined = activeMarkers.has(id)
      ? activeMarkers.get(id)
      : createMarker(stop, id)

    setMarkerPosition(marker, stop)

    return marker
  }

  const createMarker = (stop: Stop, id: string): MapMarker => {
    const image = {
      url: '/images/marker.png',
      scaledSize: new google.maps.Size(21, 31),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 27),
      labelOrigin: new google.maps.Point(11, 12),
    }

    const marker = new google.maps.Marker({
      icon: image,
    })

    if (shouldRenderEntireMap(route)) {
      const labelText = createMarkerLabelText(stop, numberOfDrivers)

      marker.setLabel({
        text: labelText,
        fontSize:
          labelText.length > 3 ? '7px' : labelText.length > 2 ? '8px' : '10px',
      })
    }

    marker.setMap(map)
    activeMarkers.set(id, marker)

    return marker
  }

  const createMarkerLabelText = (stop: Stop, numberOfDrivers: number) => {
    if (!stop.localData || stop.localData.stopNumber === -1) return '•'

    return numberOfDrivers > 1
      ? `${stop.localData.driverNumber + 1}.${stop.localData.stopNumber + 1}`
      : `${stop.localData.stopNumber + 1}`
  }

  const setMarkerPosition = (marker: MapMarker | undefined, stop: Stop) => {
    if (
      !marker?.getPosition() ||
      marker.getPosition()?.lat() !== stop.latitude ||
      marker.getPosition()?.lng() !== stop.longitude
    ) {
      marker!.setPosition({ lat: stop.latitude, lng: stop.longitude })
    }
  }

  const positionMap = () => {
    if (!stops) return
    if (!map) return

    const bounds = createBounds(stops)

    if (bounds && !bounds.isEmpty()) {
      map.fitBounds(bounds)
    }
  }

  return (
    <GoogleMapReact
      center={{ lat: 51.509865, lng: -0.118092 }}
      defaultZoom={3}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
    />
  )
}

export default React.memo(GoogleMap)
