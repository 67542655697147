import React from 'react'
import { FlatfileButton } from '@flatfile/react'
import { useIntl } from 'react-intl'

import {
  flatfileButtonSettings as settings,
  flatfileLicenseKey,
} from '$/config'
import { useRouteState, useUserState } from '$/hooks'

import { DropdownItem } from '../../Dropdown/Dropdown'
import { importStops } from '../../../api/geolocation'

interface LineFromSpreadsheet {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  country: string
  notes: string
}

interface Props {
  children?: React.ReactNode
  setIsOpen: (arg0: boolean) => void
}

const FromSpreadsheet: React.FC<Props> = ({ setIsOpen }) => {
  const route = useRouteState()
  // const stops = useStopsState()
  const user = useUserState()

  const handleClick = (launch: () => void) => {
    setIsOpen(false)
    launch()
  }

  const handleImported = async (allData: LineFromSpreadsheet[]) => {
    const queries = allData.map(
      ({
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        notes,
      }: LineFromSpreadsheet) => {
        return {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          state,
          country,
          notes,
        }
      },
    )

    await importStops(user?.uid, route?.ref.id, queries)
  }

  const label = useIntl().formatMessage({
    id: 'app.importFromSpreadsheet',
  })

  return (
    <FlatfileButton
      licenseKey={flatfileLicenseKey}
      customer={{ userId: '12345' }}
      role="menuitem"
      settings={settings}
      onData={async ({ allData }) => {
        await handleImported(allData)
      }}
      render={(importer, launch) => {
        return (
          <DropdownItem
            onClick={() => {
              handleClick(launch)
            }}
            text={label}
          />
        )
      }}
    />
  )
}

export default FromSpreadsheet
