import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  useDispatch,
  useDialogState,
  useRouteState,
  useUserState,
} from '$/hooks'

import Dialog from '../Dialog/Dialog'
import { Section, Title, Text, FooterTwoItems } from '../Form/Auth.styled'
import { BorderedButton, Button } from '../Button/Button.styled'
import { Textarea } from '../Form/Form.styled'
import { importStops } from '../../api/geolocation'
import LoadingIcon from '../Icons/Loading'

const BatchGeocodeDialog: React.FC = () => {
  const dispatch = useDispatch()
  const dialogState = useDialogState()
  // const stops = useStopsState()
  const route = useRouteState()
  const user = useUserState()
  const [isImporting, setImporting] = useState(false)

  const textareaElement: React.Ref<HTMLTextAreaElement> = useRef(null)

  const onClickCancel = () => {
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' })
  }

  const geocodeBatchStops = async () => {
    if (textareaElement?.current?.value) {
      setImporting(true)

      const queries = textareaElement.current.value
        .split('\n')
        .filter((query) => query && query.length > 0)
        .map((query) => {
          return {
            addressLineOne: query,
          }
        })

      await importStops(user?.uid, route?.ref.id, queries)

      onClickCancel()
    }
  }

  return (
    <Dialog open={dialogState === 'pasteMultipleLocations'}>
      <Section>
        <Title>
          <FormattedMessage id="app.addMultipleLocations" />
        </Title>
        <Text>
          <FormattedMessage id="app.addAddressPerLine" />
        </Text>
        <Textarea
          ref={textareaElement}
          cols={49}
          rows={8}
          placeholder={useIntl().formatMessage({ id: 'app.pasteAddressList' })}
        />
        <FooterTwoItems>
          <div>
            <BorderedButton type="button" onClick={onClickCancel}>
              <FormattedMessage id="app.cancel" />
            </BorderedButton>
          </div>
          <div>
            <Button type="button" onClick={geocodeBatchStops}>
              {isImporting && <LoadingIcon />}
              <FormattedMessage id="app.addLocations" />
            </Button>
          </div>
        </FooterTwoItems>
      </Section>
    </Dialog>
  )
}

export default BatchGeocodeDialog
