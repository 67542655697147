import React from 'react'

import OptimizedStopsPrint from './OptimizedStopsPrint'
import UnoptimizedStopsPrint from './UnoptimizedStopsPrint'

const RoutePrint = (): React.ReactElement | null =>
  // Only render client-side
  process.browser ? (
    <>
      <OptimizedStopsPrint />
      <UnoptimizedStopsPrint />
    </>
  ) : null

export default RoutePrint
