import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  getOptimizedStops,
  getDriverColor,
  getStopSummary,
} from '$/utils/stops/optimization'
import type { Stops } from '$/types'

import { FormHeader } from '../Form/Form.styled'
import RouteStop from '../RouteStop/RouteStop'
import { CarIcon } from '../shared/RouteStop/RouteStop.styled'

interface OptimizedStopsPerVehicleProps {
  stops: Stops
  vehicleNumber: number
}

const OptimizedStopsPerVehicle: React.FC<OptimizedStopsPerVehicleProps> = ({
  stops,
  vehicleNumber,
}) => {
  const optimizedStops = getOptimizedStops(stops, vehicleNumber)
  const driverColor = getDriverColor(optimizedStops[0][1])

  return (
    <div>
      <FormHeader>
        <CarIcon color={driverColor} />
        <FormattedMessage
          id="app.vehicleNumber"
          values={{ vehicleNumber: vehicleNumber + 1 }}
        />
        <span>{getStopSummary(stops, vehicleNumber)}</span>
      </FormHeader>

      {optimizedStops.map(([id, stop], index) => (
        <RouteStop
          key={`route-stop-${index}`}
          id={id}
          index={index}
          stop={stop}
          color={getDriverColor(stop)}
        />
      ))}
    </div>
  )
}

export default OptimizedStopsPerVehicle
