import styled, { css } from 'styled-components'
import tw from 'twin.macro'

interface ContainerProps {
  skipPageAfter?: boolean
}

export const Container = styled.div<ContainerProps>`
  ${tw`
    bg-white
    hidden
    p-8
    w-screen

    print:block
  `}

  ${({ skipPageAfter }) =>
    skipPageAfter &&
    css`
      ${tw`
        min-h-screen
      `}
    `}
    page-break-after: always;
`

export const RouteName = styled.h1`
  ${tw`
    font-light
    mb-8
    text-3xl
  `}
`
export const Table = styled.table`
  ${tw`
    table-auto
    w-full
    p-2
    text-base
  `}
`

export const TableHead = styled.th.attrs({ colSpan: 2 })`
  ${tw`
    border
    bg-warmGray-100
    border-warmGray-300
    p-4
    text-left
  `}
  border-bottom-color: black;
  border-bottom-width: 2px;

  > div {
    ${tw`
      flex
      items-center
    `}

    div {
      ${tw`
        inline-block
      `}
    }
  }

  span {
    ${tw`
      font-normal
      text-black
      pl-4
    `}
  }
`

export const TableRow = styled.tr`
  ${tw`
    p-2
  `}

  > td:nth-child(1) {
    ${tw`
      font-bold
      text-center
    `}
  }
`

export const TableData = styled.td`
  ${tw`
    align-top
    border
    border-warmGray-300
    p-3
  `}

  p {
    ${tw`
      text-warmGray-700
      m-0
    `}

    & :first-of-type {
      ${tw`
        text-warmGray-500
      `}
    }

    b {
      ${tw`
        text-black
      `}
    }
  }

  p + p {
    ${tw`
      mb-0
    `}
  }
`
