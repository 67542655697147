import type { Stop, Stops } from '$/types'
import { driverColors } from '$/config'

export const getDriverColor = (stop: Stop | null): string => {
  const driverNumber = stop?.localData?.driverNumber

  return stop && driverNumber != null
    ? driverColors[driverNumber % driverColors.length]
    : '#000'
}

export const getUnoptimizedStops = (
  stops: Stops,
): Array<[string, Stop | null]> => {
  return [...stops.entries()].filter((stop) => {
    const [, stopProps] = stop

    return (
      stopProps == null ||
      !stopProps?.optimized ||
      stopProps?.localData.driverNumber === -1
    )
  })
}

export const getOptimizedStops = (
  stops: Stops,
  vehicleNumber: number,
): Array<[string, Stop | null]> => {
  return [...stops.entries()].filter((entry) => {
    const [, stopProps] = entry

    return stopProps?.localData.driverNumber === vehicleNumber
  })
}

export const unoptimizedStopsExist = (stops: Stops): boolean => {
  return [...stops.entries()].some((entry) => {
    const [, stopProps] = entry

    return stopProps == null || stopProps?.localData.driverNumber === -1
  })
}

export const optimizedStopsExist = (stops: Stops): boolean => {
  return [...stops.entries()].some((entry) => {
    const [, stopProps] = entry

    return (
      stopProps?.localData.driverNumber != null &&
      stopProps?.localData.driverNumber !== -1
    )
  })
}

export const getStopSummary = (stops: Stops, vehicleNumber: number): string => {
  let stopsDistance = 0
  let stopsDuration = 0

  for (const [, stop] of getOptimizedStops(stops, vehicleNumber)) {
    if (stop == null || stop.localData.driverNumber !== vehicleNumber) continue

    stopsDistance += stop.distanceMeters
    stopsDuration += stop.durationSeconds
  }

  const distance = formatMeters(stopsDistance)
  const duration = formatSeconds(stopsDuration)

  return `${distance} · ${duration}`
}

export const getVehiclesWithStops = (stops: Stops): Array<number | undefined> =>
  [...stops.values()]
    .map((stop) => stop?.localData.driverNumber)
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter((number) => number !== undefined && number !== -1)

// Private

// Todo: i18n this
const formatMeters = (meters: number): string =>
  `${(meters / 1000).toFixed(0)}km`

// Todo: i18n this
const formatSeconds = (seconds: number): string => {
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = ((seconds - hours * 60 * 60) / 60).toFixed(0)

  return hours < 1 ? `${minutes}m` : `${hours}h ${minutes}m`
}
