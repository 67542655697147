import React from 'react'

import { useStopsState } from '$/hooks'
import { getVehiclesWithStops } from '$/utils/stops/optimization'

import OptimizedStopsPerVehiclePrint from './OptimizedStopsPerVehiclePrint'

const OptimizedStopsPrint = (): React.ReactElement | null => {
  const stops = useStopsState()

  const vehiclesWithStops = getVehiclesWithStops(stops)

  return (
    <>
      {vehiclesWithStops.map((vehicleNumber, index) => {
        return (
          <OptimizedStopsPerVehiclePrint
            key={`print-optimized-route-key-${index}`}
            vehicleNumber={vehicleNumber!}
            vehicles={vehiclesWithStops.length}
          />
        )
      })}
    </>
  )
}

export default OptimizedStopsPrint
