import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'

import {
  useStopsState,
  useOnClickOutside,
  useRouteState,
  useDispatch,
} from '$/hooks'
import { exportToCSV } from '$/utils/route/export'

import * as StopAPI from '../../api/stop'
import * as RouteAPI from '../../api/route'
import { Dropdown, DropdownItem } from '../Dropdown/Dropdown'
import {
  EllipsisIcon,
  ExportIcon,
  PrintIcon,
  ResetIcon,
} from './CalculateRoute.styled'
import { ButtonAction } from '../Button/Button.styled'

interface Props {
  setEmptyStopVisible: (visible: boolean) => void
}

const OptimizeOptions: React.FC<Props> = ({ setEmptyStopVisible }) => {
  const ref = useRef() as React.MutableRefObject<HTMLButtonElement>

  const dispatch = useDispatch()
  const stops = useStopsState()
  const route = useRouteState()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  const handleToggleDropdown = () => setIsOpen(!isOpen)

  const handleExportRoute = () => {
    exportToCSV(stops)
  }

  const handleResetRoute = async () => {
    setIsOpen(!isOpen)
    await StopAPI.batchDelete(route, stops)
    await dispatch({ type: 'DELETE_ALL_STOPS' })

    // Ensure we show the empty stops after a cleanup
    setEmptyStopVisible(true)

    return RouteAPI.update(route!.ref!, {
      maximumNumberOfVehicles: '1',
    })
  }

  const handlePrintRoute = async () => {
    window.print()
  }

  const dropdownLabels = {
    print: useIntl().formatMessage({ id: 'app.printRoute' }),
    reset: useIntl().formatMessage({ id: 'app.resetRoute' }),
    export: useIntl().formatMessage({ id: 'app.exportRoute' }),
  }

  return (
    <React.Fragment>
      {isOpen && (
        <Dropdown position="toTop">
          <DropdownItem
            onClick={handlePrintRoute}
            text={dropdownLabels.print}
            icon={<PrintIcon />}
          />
          <DropdownItem
            onClick={handleExportRoute}
            text={dropdownLabels.export}
            icon={<ExportIcon />}
          />
          <DropdownItem
            onClick={handleResetRoute}
            text={dropdownLabels.reset}
            icon={<ResetIcon />}
          />
        </Dropdown>
      )}
      <ButtonAction
        role="toggle-calculate-options"
        ref={ref}
        onClick={handleToggleDropdown}
      >
        <EllipsisIcon />
      </ButtonAction>
    </React.Fragment>
  )
}

export default OptimizeOptions
