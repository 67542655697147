import styled from 'styled-components'

import colors from '$/theme/colors'

export const Loading = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  border: 3px solid rgba(0, 0, 0, 0.05);
  border-left-color: ${colors.dark['30']};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.6s linear infinite;
`
